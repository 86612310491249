import React from 'react';
import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';

import SEO from 'components/SEO';
import { BREAKPOINTS, MOBILE_SIDE_PADDING } from 'utils/constants';

export const query = graphql`
  query NewsPageQuery {
    allPrismicNewsPage {
      nodes {
        uid
        data {
          author_image {
            alt
            copyright
            url
            gatsbyImageData
          }
          author_name {
            text
            html
            raw
          }
          author_quote {
            text
            html
            raw
          }
          og_description {
            text
            html
            raw
          }
          og_image {
            alt
            copyright
            url
            gatsbyImageData
          }
          og_title {
            text
            html
            raw
          }
          page_description {
            text
            html
            raw
          }
          page_image {
            alt
            copyright
            url
            gatsbyImageData
          }
          page_title {
            text
            html
            raw
          }
          post_content {
            text
            html
            raw
          }
          post_date
          post_image {
            alt
            copyright
            url
            gatsbyImageData(placeholder: BLURRED, height: 400, aspectRatio: 1.2)
          }
          post_title {
            text
            html
            raw
          }
          post_type {
            text
            html
            raw
          }
          twitter_description {
            text
            html
            raw
          }
          twitter_image {
            alt
            copyright
            url
            gatsbyImageData
          }
          twitter_title {
            text
            html
            raw
          }
        }
      }
    }
    allPrismicNewsRootPage {
      nodes {
        dataRaw
      }
    }
  }
`;

const PostsContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.backgroundWhite,
  display: 'flex',
  justifyContent: 'center',
}));

const PostsContent = styled.div({
  display: 'flex',
  maxWidth: 1200,
  width: '100%',
  flexWrap: 'wrap',
  padding: `160px 100px 120px 100px`,
  [BREAKPOINTS[800]]: {
    maxWidth: 360,
    paddingLeft: MOBILE_SIDE_PADDING,
    paddingRight: MOBILE_SIDE_PADDING,
  },
});

const PostContainer = styled.div({
  marginBottom: 40,
  cursor: 'pointer',
  margin: 20,
  [BREAKPOINTS[800]]: {
    marginLeft: 0,
    marginRight: 0,
  },
});

const FirstPostAuthor = styled.div((props) => ({
  fontSize: 15,
  letterSpacing: 1,
  marginBottom: 8,
  fontWeight: 700,
  color: props.theme.colors.white,
}));

const FirstPostText = styled.div({
  marginLeft: 60,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 'calc(100%/2 - 40px)',
  maxWidth: 400,
  [BREAKPOINTS[800]]: {
    marginLeft: 0,
    marginBottom: 16,
    width: '100%',
  },
});

const PostAuthor = styled.div((props) => ({
  fontSize: 15,
  letterSpacing: 1,
  marginTop: 16,
  marginBottom: 12,
  fontWeight: 700,
  color: props.theme.colors.dark,
}));

const PostTitle = styled.div((props) => ({
  fontSize: 18,
  letterSpacing: 1,
  lineHeight: '27px',
  marginBottom: 12,
  color: props.theme.colors.dark,
}));

const FirstPostTitle = styled(PostTitle)((props) => ({
  color: props.theme.colors.white,
}));

const PostDate = styled.div({
  color: 'rgba(21, 36, 48, 0.4)',
  fontSize: 15,
});

const FirstPostDate = styled(PostDate)((props) => ({
  color: props.theme.colors.white,
  opacity: 0.4,
}));

const PostLink = styled(Link)({
  textDecoration: 'none',
  width: 'calc(100%/3)',
  [BREAKPOINTS[1100]]: {
    width: 'calc(100%/2)',
  },
  [BREAKPOINTS[800]]: {
    width: '100%',
  },
});

const FirstPostLink = styled(Link)({
  textDecoration: 'none',
  width: '100%',
  maxWidth: 1200,
  padding: `0 100px`,
  [BREAKPOINTS[800]]: {
    maxWidth: 360,
    padding: `0 ${MOBILE_SIDE_PADDING}`,
  },
});

const FirstPostContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.blue,
  height: 518,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  [BREAKPOINTS[800]]: {
    justifyContent: 'center',
    height: 650,
  },
}));

const FirstPostContent = styled.div({
  display: 'flex',
  marginBottom: -66,
  marginLeft: 20,
  [BREAKPOINTS[800]]: {
    marginLeft: 0,
    flexDirection: 'column-reverse',
    maxWidth: 360,
  },
});

const Post = ({ image, title, date, url, author }) => (
  <PostLink to={url}>
    <PostContainer>
      <GatsbyImage image={image} alt={title} loading="lazy" />
      <PostAuthor>{author}</PostAuthor>
      <PostTitle>{title}</PostTitle>
      <PostDate>{date}</PostDate>
    </PostContainer>
  </PostLink>
);

const FirstPost = ({ image, title, date, url, author }) => (
  <FirstPostContainer>
    <FirstPostLink to={url}>
      <FirstPostContent>
        <GatsbyImage image={image} alt={title} loading="lazy" />
        <FirstPostText>
          <FirstPostAuthor>{author}</FirstPostAuthor>
          <FirstPostTitle>{title}</FirstPostTitle>
          <FirstPostDate>{date}</FirstPostDate>
        </FirstPostText>
      </FirstPostContent>
    </FirstPostLink>
  </FirstPostContainer>
);

const News = ({ data }) => {
  const pageData = data.allPrismicNewsRootPage.nodes[0].dataRaw;
  const posts = data.allPrismicNewsPage.nodes;

  const sortByDate = (a, b) =>
    a.data.post_date < b.data.post_date ? 1 : b.data.post_date < a.data.post_date ? -1 : 0;

  const sortedPosts = posts.sort(sortByDate);

  const firstPost = sortedPosts[0];

  return (
    <>
      <SEO
        pageTitle={pageData.page_title && RichText.asText(pageData.page_title)}
        ogTitle={pageData.og_title && RichText.asText(pageData.og_title)}
        twitterTitle={pageData.twitter_title && RichText.asText(pageData.twitter_title)}
        pageDescription={pageData.page_description && RichText.asText(pageData.page_description)}
        ogDescription={pageData.og_description && RichText.asText(pageData.og_description)}
        twitterDescription={
          pageData.twitter_description && RichText.asText(pageData.twitter_description)
        }
        pageImage={pageData?.page_image?.url}
        pageImageAlt={pageData?.page_image?.alt}
        twitterImage={pageData?.twitter_image?.url}
        twitterImageAlt={pageData?.twitter_image?.alt}
        ogImage={pageData?.og_image?.url}
        ogImageAlt={pageData?.og_image?.url?.alt}
      />
      <FirstPost
        image={getImage(firstPost.data.post_image)}
        title={RichText.asText(firstPost.data.post_title.raw)}
        author={RichText.asText(firstPost.data.author_name.raw)?.toUpperCase()}
        date={firstPost.data.post_date}
        url={firstPost.uid}
      />
      <PostsContainer>
        <PostsContent>
          {sortedPosts.map(
            (post, index) =>
              index !== 0 && (
                <Post
                  key={post.uid}
                  image={getImage(post.data.post_image)}
                  title={RichText.asText(post.data.post_title.raw)}
                  date={post.data.post_date}
                  author={RichText.asText(post.data.author_name.raw)?.toUpperCase()}
                  url={post.uid}
                />
              )
          )}
        </PostsContent>
      </PostsContainer>
    </>
  );
};

export default News;
